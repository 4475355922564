/* responsive module */

(function ($, app) {
    "use strict";

    app.cookieFirst = function () {
        if($("body.us").length){
            // $('body').addClass('no-c1st');
            // console.log('kjdjdjdjdhdhdhhddh');
            function setCookie(cname, cvalue, exdays) {
                const d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                let expires = "expires="+ d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            }
            function showCookiePopup () {
                function cookieBanner(data) {
                    var us  = ['California', 'Texas', 'Nebraska'];
                    if (data.country == 'US' && data.region && jQuery.inArray(data.region, us) == -1) {                        
                        if (document.cookie.indexOf("prCookieOk") < 0) {
                            jQuery('.cookie-bar').removeClass('d-none');
                            CookieFirst.acceptAllCategories();
                        } else {
                            jQuery('body').addClass('show-c1st');
                        }
                        jQuery('.cookie-bar a').on('click', function(e) {
                            setCookie('prCookieOk', true, 365);
                            jQuery('body').addClass('show-c1st');
                            jQuery('.cookie-bar').addClass('d-none');
                            e.preventDefault();
                            CookieFirst.openPanel()
                        });
                        jQuery('.cookie-bar button').on('click', function(e) {
                            e.preventDefault();                
                            setCookie('prCookieOk', true, 365);
                            jQuery('.cookie-bar').addClass('d-none');
                            jQuery('body').addClass('show-c1st');
                        })
                    } else {
                        if (document.cookie.indexOf("prCookieOk") >= 0) {
                            CookieFirst.withdrawConsent();
                        }
                        jQuery('body').addClass('show-c1st');                        
                    }
                }
                function cookieBannerFailed() {
                    jQuery('body').addClass('show-c1st'); 
                }
                getLocal();
                function getLocal(){
                    $.ajax({
                       url: 'https://ipv4-check-perf.radar.cloudflare.com/api/info',
                       type: 'get',
                       dataType: 'json',
                       cache: false,
                       success: cookieBanner,
                       error: cookieBannerFailed,
                       async:true,
                    });
               };
            }
            if (CFinit) {       
                showCookiePopup();
            } else {
                window.addEventListener("cf_init", function() {
                    showCookiePopup();
                });
            }
        }
    };
})(jQuery, app);