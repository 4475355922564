(function ($, app, _) {
    "use strict";

    app.plyrVideo = function () {
        if($(".plyrVideo").length){
            var autoPlay = ($(".plyrVideo").data('pr-plyr-autoplay')) ? true : false,
                muted    = ($(".plyrVideo").data('pr-plyr-muted')) ? true : false,
                playL    = (!$(".plyrVideo").data('pr-plyr-autoplay')) ? 'play-large' : '',
                controls = ($(".plyrVideo").data('pr-plyr-controls')) ? [
                    'play-large', 
                    'play', 
                    'progress', 
                    'current-time', 
                    'mute', 
                    'volume', 
                    'pip', 
                    'airplay', 
                    'fullscreen'
                ] : [ playL, 'play', 'progress','fullscreen' ];
                
                if(typeof players !== 'undefined'){
                    const players = Array.from(document.querySelectorAll('.plyrVideo')).map((p) => new Plyr(p,{
                        autoplay: autoPlay,
                        muted: muted,
                        playsinline: true,
                        controls: controls,
                    }));
                }else{
                    const players = Array.from(document.querySelectorAll('.plyrVideo')).map((p) => new Plyr(p,{
                        autoplay: autoPlay,
                        muted: muted,
                        playsinline: true,
                        controls: controls,
                    }));
                }
        }
    };

})(jQuery, app, _);