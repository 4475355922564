(function ($, app) {
    "use strict";

    app.m62 = function () {
        if($(".reloading-form").length){
            $(document).ready(function(){
                window.addEventListener('message', event => {
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                        $('.reloading-form.m69').addClass('d-none');
                        $('.thanks-container').removeClass('d-none');
                        $('.m69-form-container h3').addClass('d-none');
                        $('.reloadForm').on('click', function() {
                            hbspt.forms.create({
                                region: "na1",
                                portalId: "7061715",
                                formId: event.data.id,
                                target: ".reloading-form.m69" // Add the element class where you need to create the form again
                            });
                            $('.thanks-container').addClass('d-none');
                            $('.reloading-form.m69').removeClass('d-none');
                        })
                    }
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                        $('.thanks-container').addClass('d-none');
                        $('.m69-form-container h3').removeClass('d-none');
                    }
                });

            });
        }
        // REDIRECTION IF URL IS AVAILABLE
        if($(".redirect-form").length){
            var redirectURL = $(".redirect-form").attr("hs-redirect");
            $(document).ready(function(){
                window.addEventListener('message', event => {
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                        window.location.href = redirectURL;
                    }
                });
            });
        };
        
        // SHOW THANKS MESSAGE
        if($(".hs-embeded-form").length){
            $(document).ready(function(){
                window.addEventListener('message', event => {
                    if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                        $('html, body').animate({
                          scrollTop: $(".thanks-container").parent().closest('section').offset().top
                        }, 200);
                        $('.thanks-container').removeClass('d-none');
                    }
                });
            });
        }
       
        setTimeout(function(){
            if($(".hs_url_submission_page").length){
                $(".hs_url_submission_page input").val(window.location.href)
            }
        }, 900);
        
            
       
        $(document).ready(function(){
            if($('.m62 #request-type').length){
                $('#company').parent().parent().hide();

                $('.m62 #request-type').on('change',function(){
                    let $option = $(this).val();
                    if($option == "Company"){
                        $('#company').val('');
                        $('#company').parent().parent().show(400);
                    }
                    else{
                        $('#company').parent().parent().hide(400);
                        setTimeout(function(){
                            $('#company').val('Not applicable');
                        }, 400);
                    }
                });
            }
        });
        $('#request_partnership_for').change(function(){
            if($(this).val() == 'Company'){ // or this.value == 'volvo'
                $('.partners_company').removeClass('d-none');
                $('.partners_company input').prop('required',true);
            } else {
                $('.partners_company').addClass('d-none');
                $('.partners_company input').prop('required',false);
            }
          });

    };
})(jQuery, app); 